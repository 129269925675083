import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { Navbar, Nav, NavDropdown, Modal, Button, Badge } from 'react-bootstrap';

import { X } from 'react-bootstrap-icons';

import Image from './image';
import Footer from '../components/views/footer'

export default (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const data = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `
    )

    return (
        <>
            <style type="text/css">
            {`
                .bg-light {
                    background-color: #fff !important;
                }
                .navbar {
                    padding: 24px 40px;
                    box-shadow: 0 6px 15px 2px rgba(0, 0, 0, 0.12);
                }
                .navbar-brand {
                    width: 140px;
                    padding: 20px 40px;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    left: 0;
                    box-shadow: 0 6px 15px 2px rgba(0, 0, 0, 0.12);
                }
                .navbar-brand img {
            
                }
                .navbar-light .navbar-nav .nav-link {
                    color: #2D2118;
                    font-size: 17px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-weight: 700;
                    
                }
                .navbar-light .navbar-toggler {
                    border: none;
                    outline: 0;
                    padding: 0;
                }
                .dropdown-menu { 
                    border-radius: 2px;
                    padding: 0;
                    box-shadow: 0 6px 15px 2px rgba(57, 48, 96, 0.12);
                    border: none;
                    overflow: hidden;
                }
                .dropdown-item {
                    font-size: 18px;
                    padding: 10px 25px;
                    font-weight: 300;
                }
                .dropdown-item:hover, .dropdown-item:focus {
                    color: #fff;
                    background-color: #AB2430;
                }
                @media(min-width: 768px) {
                    .navbar-light .navbar-nav .nav-link {
                        padding: 0 24px;
                    }
                    .navbar-btn-right {
                        margin-left: 30px;
                    }
                    .dropdown-menu {
                        top: 150%;
                        left: 24px;
                    }
                }
                @media(max-width: 767px) {
                    .navbar-collapse {
                        margin-top: 100px;
                    }
                    .navbar-light .navbar-nav .nav-link {
                        margin-bottom: 12px;
                    }
                    .navbar-brand {
                        width: 100px;
                        padding: 10px 20px;
                    }
                }
                .btn-navbar-custom {
                    padding: 12px 50px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 15px;
                }
                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 13px;
                    font-size: 30px;
                    cursor: pointer;
                    z-index: 100;
                    border: 0;
                    background-color: #fff;
                    padding: 0;
                }
            `}
            </style>
            <Navbar bg="light" expand="lg" fixed="top">
                <Link to={`/`}>
                    <Navbar.Brand>
                        <Image src="mekeifoundation-logo.png" alt={data.site.siteMetadata.title} />
                    </Navbar.Brand>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav><Link className="nav-link" to={`/`}>Нүүр</Link></Nav>
                        <NavDropdown title="Мекей сан" id="service-nav-dropdown">
                            <Link className="dropdown-item" to={`/about-mekei-foundation`}>Мекей сангийн тухай</Link>
                            <Link className="dropdown-item" to={`/about-mekei`}>Мекей багшийн тухай</Link>
                            <Link className="dropdown-item" to={`/our-cause-and-programs`}>Сангийн хөтөлбөр</Link>
                        </NavDropdown>
                        <Nav><Link className="nav-link" to={`/news`}>Мэдээ, үйл явдал</Link></Nav>
                        <Nav><Link className="nav-link" to={`/contact`}>Холбоо барих</Link></Nav>
                    </Nav>
                    <Button className="navbar-btn-right btn btn-primary btn-lg btn-navbar-custom" onClick={handleShow}>Хандив</Button>
                </Navbar.Collapse>
            </Navbar>
            {props.children}
            <Footer />
            <Modal show={show} onHide={handleClose} animation={false}>
                <button type="button" className="close-button" onClick={handleClose} aria-label="close"><X /></button>
                <Modal.Body className="text-center">
                    <h3>Голомт банк</h3> <Badge variant="secondary">1415102228</Badge> <br />Мекей Сан
                </Modal.Body>
            </Modal>
        </>
    )
}