import React from 'react'
import { Link } from 'gatsby'

import { Container, Row, Col } from 'react-bootstrap';

import Image from '../image';

import { Telephone, Envelope, Building } from 'react-bootstrap-icons';

export default () => {
    return(
        <>
        <style>{`
            .footer {
                background-color: #fff;
                border-top: solid 1px #E6DDD6;
            }
            .footer-logo {
                width: 50px;
            }
            .footer-copyright {
                font-weight: 300;
                font-size: 14px;
            }
            .footer h3 {
                margin-bottom: 15px;
                text-transform: uppercase;
                font-size: 18px;
                color: #2D2118;
                letter-spacing: 2px;
                font-weight:bold;
            }
            .footer ul {
                margin: 0;
                padding: 0;
                list-style: none;
            }
            .footer ul li a {
                font-size: 17px;
                font-weight: 500;
                color: #2D2118;
                line-height: 2em;
                text-decoration: none;
                letter-spacing: 1px;
                font-weight: 400;
            }
            .footer ul li a:hover {
                text-decoration: underline;
            }
            .contact-list, .contact-list a {
                color: #2D2118;
                font-size: 17px;
                letter-spacing: 1px;
                line-height: 2;
                text-decoration: none;
            }
            .contact-list img {
                width: 22px;
            }
            @media(min-width: 768px) {
                .footer {
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }
            @media(max-width: 767px) {
                .footer {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        `}</style>
        <div className="section footer">
            <Container fluid>
                <Row>
                    <Col md={3}>
                        <div className="mb-4">
                            <Image className="footer-logo" src="mekeifoundation-logo.png" alt="mekeifoundation.org" />
                        </div>
                        <p className="footer-copyright">
                            © 2020 mekeifoundation.org. <br />All rights reserved.
                        </p>
                    </Col>
                    <Col md={3}>
                        <h3>Мекей сан</h3>
                        <ul>
                            <li><Link to={`/about-mekei-foundation`}>Мекей сангийн тухай</Link></li>
                            <li><Link to={`/about-mekei`}>Мекей багшийн тухай</Link></li>
                            <li><Link to={`/our-cause-and-programs`}>Сангийн хөтөлбөр</Link></li>
                        </ul>
                    </Col>
                    <Col md={3}>
                        <ul>
                            <li><Link to={`/`}>Нүүр</Link></li>
                            <li><Link to={`/news`}>Мэдээ, үйл явдал</Link></li>
                            <li><Link to={`/contact`}>Холбоо барих</Link></li>
                        </ul>
                    </Col>
                    <Col md={3}>
                        <h3>Холбогдох</h3>
                        <span className="contact-list"><Telephone /> <a href="tel:+976 9914-4479">+976 9914-4479</a></span><br />
                        <span className="contact-list"><Envelope /> <a href="mailto:mgulfira@gmail.com">mgulfira@gmail.com</a></span><br />
                        <span className="contact-list"><img src="/img/facebook-logo.png" alt="Facebook logo" /> <a target="_blank" rel="noreferrer" href="https://www.facebook.com/%D0%9C%D0%B5%D0%BA%D0%B5%D0%B9-%D0%A1%D0%B0%D0%BD-101715318396833">Мекей Сан</a></span><br />
                        <span className="contact-list"><Building /> Голомт банк 1415102228 Мекей Сан</span><br />
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}